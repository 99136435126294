::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-button {
  width: 100px;
  height: 100px;
}
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 24px solid #ffffff;
  border-radius: 96px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
::-webkit-scrollbar-thumb:active {
  background: #000000;
}
::-webkit-scrollbar-track {
  background: #666666;
  border: 6px none #000000;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.zoom-background {
    position: relative;
  }
.zoom-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  border-radius: 10px;
  z-index: 1; 
}

.background-image{
  background-image: url('./assets/diseño-de-fondo3.jpg');
  background-size: cover;
  object-fit: cover;
}

.bg-color{
  background-color: #54b1c8;
}

.bg-butomLogin{
  background-color: #9ed9df;
}

.blur-image {
  filter: blur(4px); 
  opacity: 0.8; 
}